<template>
  <div class="legal-nutzung legal-doc-view">
    <p><strong>Nutzungsbedingungen</strong></p>

    <p>Allgemeine Geschäftsbedingungen für die Nutzung der Internetseite individuallist.de</p>

    <p>(Stand: 15. Januar 2018)</p>

    <p>Alle Leistungen auf der Internetseite individuallist.de sowie deren Unterseiten werden von der Individuallist UG (haftungsbeschränkt), Mettmanner Straße 11, 40233 Düsseldorf ausschließlich auf Grundlage der folgenden Allgemeinen Geschäftsbedingungen angeboten.</p>

    <p><strong>§ 1 Leistungen der Individuallist UG</strong></p>

    <p>I. Die Internetseite individuallist.de ist eine Plattform im Internet, auf der natürliche <em>und/oder juristische Personen</em>, die ein Konto angemeldet haben („Benutzer"), an einem sozialen Netzwerk mit mehreren Unterseiten teilnehmen und innerhalb der Gruppe der angemeldeten Benutzer Waren und Dienstleistungen aller Art anbieten, vertreiben und erwerben können. Dies gilt jedoch nur, sofern die Teilnahme am sozialen Netzwerk oder das Angebot, der Vertrieb oder Erwerb der Artikel nicht gegen gesetzliche Vorschriften oder diese Allgemeinen Geschäftsbedingungen verstößt.</p>

    <p>II. Die Individuallist UG (haftungsbeschränkt) stellt den Benutzern auf der Internetseite individuallist.de die technische Möglichkeit zur Verfügung, im jeweils angebotenen Rahmen an einem sozialen Netzwerk mit mehreren Unterseiten teilzunehmen und innerhalb der Gruppe der angemeldeten Benutzer Waren und Dienstleistungen anzubieten, zu vertreiben und zu erwerben.</p>

    <p>III. Das Angebot der Individuallist UG (haftungsbeschränkt) beschränkt sich ausdrücklich auf die Zurverfügungstellung der Internetseite individuallist.de. Die Individuallist UG (haftungsbeschränkt) ist berechtigt, ihre Leistungen zeitweilig zu beschränken, teilweise oder dauerhaft einzustellen, wenn dies im Hinblick auf Kapazitätsgrenzen, die Sicherheit oder Integrität der Server oder zur Durchführung technischer Maßnahmen erforderlich ist bzw. dies der ordnungsgemäßen oder verbesserten Erbringung der Leistungen insbesondere zur Erbringung von Wartungsarbeiten dient.</p>

    <p><strong>§ 2 Anmeldung und Benutzerkonto</strong></p>

    <p>I. Die Nutzung der Internetseite individuallist.de setzt die kostenfreie Anmeldung als Benutzer und die Einrichtung eines Benutzerkontos („Konto") voraus. Sie erfolgt durch Eröffnung eines Kontos unter Zustimmung zu diesen Allgemeinen Geschäftsbedingungen. Mit der Anmeldung kommt zwischen der Individuallist UG (haftungsbeschränkt) und dem Benutzer ein Vertrag über die Nutzung der Internetseite individuallist.de („Nutzungsvertrag") zustande. Ein Anspruch auf Abschluss eines Nutzungsvertrags besteht nicht.</p>

    <p>II. Die Anmeldung ist nur <em>juristischen Personen, Personengesellschaften und </em>unbeschränkt geschäftsfähigen natürlichen Personen erlaubt. Minderjährige sind aufgrund ihrer beschränkten Geschäftsfähigkeit von der Nutzung der angebotenen Dienste ausgeschlossen.</p>

    <p>III. Die für die Anmeldung auf der Internetseite individuallist.de notwendigen Daten wie Vor- und Nachname, die aktuelle Adresse (kein Postfach) und Telefonnummer (keine Mehrwertdienstrufnummern), eine gültige E-Mail-Adresse <em>sowie gegebenenfalls die Firma und ein Vertretungsberechtigter</em> sind vollständig und korrekt anzugeben. Ein Konto ist nur mit ausdrücklicher Zustimmung der Individuallist UG (haftungsbeschränkt) übertragbar.</p>

    <p>IV. Bei der Anmeldung steht jedem Benutzer die Wahl eines frei gewählten Benutzernamens nach Verfügbarkeit offen („Benutzername"). Der Benutzername <em>muss nicht</em> mit dem richtigen Namen identisch sein. Er darf nicht Rechte Dritter -- insbesondere keine Namens- oder Markenrechte -- verletzen und nicht gegen die guten Sitten verstoßen. Ein Anspruch auf einen bestimmten Benutzernamen besteht nicht. Die Individuallist UG (haftungsbeschränkt) ist bei der Bereitstellung eines Benutzernamens nicht dazu verpflichtet, den Benutzernamen auf Rechtsverstöße zu überprüfen. Sollte die Individuallist UG (haftungsbeschränkt) insbesondere von einer Rechtsverletzung Dritter durch einen Benutzernamen erlangen, so ist die Individuallist UG (haftungsbeschränkt) berechtigt, das Konto zu sperren oder einen neuen Benutzernamen zuzuteilen.</p>

    <p>V. Die Individuallist UG (haftungsbeschränkt) ist nicht verpflichtet, die bei der Anmeldung hinterlegten Daten zu überprüfen. Da nicht ausgeschlossen werden kann, dass für einen Konto falsche Kontaktdaten hinterlegt wurden, ist jeder Benutzer selbst für die Identität seines Vertragspartners verantwortlich.</p>

    <p>VI. Sollten sich nach der Anmeldung Änderungen bezüglich der angegebenen Daten ergeben, so ist der Benutzer verpflichtet, seine Benutzerdaten unverzüglich auf den aktuellen Stand zu bringen.</p>

    <p>VII. Bei der Anmeldung legt jeder Benutzer für sein Konto ein nur dem Benutzer bekanntes Passwort fest. Die Benutzer sind nicht berechtigt, ihr Passwort an Dritte weiterzugeben und verpflichten sich ihr Passwort geheim zu halten und den Zugang zu ihrem Konto sorgfältig zu sichern. Sollte ein Benutzer Anhaltspunkte für die unberechtigte Nutzung seines Kontos haben, so ist der Benutzer unverzüglich dazu verpflichtet, die Individuallist UG (haftungsbeschränkt) hierüber zu informieren und der Individuallist UG (haftungsbeschränkt) die entsprechenden Anhaltspunkte mitzuteilen. Die Individuallist UG (haftungsbeschränkt) verpflichtet sich daraufhin, das entsprechende Konto unverzüglich zu sperren.</p>

    <p>VIII. Die Individuallist UG (haftungsbeschränkt) wird das Passwort eines Benutzers nicht an Dritte weitergeben und nie per E-Mail oder Telefon oder sonst wie nach dem Passwort eines Benutzers fragen.</p>

    <p>IX. Benutzer haften grundsätzlich für sämtliche unter ihrem Benutzernamen bzw. mit ihrem Konto getätigten Aktivitäten, sofern der Benutzer den Missbrauch seines Kontos zu vertreten hat.</p>

    <p>X. Die Individuallist UG (haftungsbeschränkt) behält sich das Recht vor, Benutzerkonten nach einer angemessenen Zeit zu löschen, wenn die Angaben bei der Anmeldung nicht vollständig oder korrekt sind, und eine elektronische Kontaktaufnahme mit dem Benutzer unter Verwendung der vorliegenden Angaben nicht möglich oder ohne Antwort bleibt.</p>

    <p><strong>§ 3 Soziales Netzwerk mit Unterseiten</strong></p>

    <p>I. Die Individuallist UG (haftungsbeschränkt) betreibt auf der Internetseite individuallist.de ein soziales Netzwerk, über welches Benutzer miteinander kommunizieren und ggf. über hierzu von der Individuallist UG (haftungsbeschränkt) bereitgestellte technische Möglichkeiten miteinander interagieren können. Das von der Individuallist UG bereitgestellte soziale Netzwerk kann von Benutzern zum wechselseitigen Austausch von Meinungen, Erfahrungen und Informationen eingesetzt werden. Ebenso können die Benutzer auf der Internetseite Individuallist UG (haftungsbeschränkt) ein persönliches Profil erstellen.</p>

    <p>II. Das von der Individuallist UG (haftungsbeschränkt) auf der Internetseite individuallist.de betriebene soziale Netzwerk umfasst hierbei drei Bereiche:</p>

    <p>a) <em>Allgemeines soziales Netzwerk</em></p>

    <p>Der Bereich <em>Allgemeines soziales Netzwerk</em> erlaubt dem Benutzer, ein allgemeines persönliches Profil zu erstellen und zu beliebigen Themen seiner Wahl mit anderen Nutzern der Internetseite individuallist.de zu kommunizieren und in Interaktion zu treten.</p>

    <p>b) Soziales Netzwerk zum Bereich <em>Dating</em></p>

    <p>Über die Unterseite <em>Dating</em> bietet die Individuallist UG (haftungsbeschränkt) die Funktion eines sozialen Netzwerks, welches auf ein gemeinschaftliches Kennenlernen der Benutzer ausgerichtet ist, an.</p>

    <p>c) Soziales Netzwerk zum Bereich <em>Beruf</em></p>

    <p>Über die Unterseite <em>Dating</em> bietet die Individuallist UG (haftungsbeschränkt) die Funktion eines berufsbezogenen Netzwerks, welches auf eine berufliche Präsentation und Kommunikation und Interaktion zu geschäftlichen Themen ausgelegt ist, an.</p>

    <p><strong>§ 4 Pflichten der Benutzer bei Nutzung des sozialen Netzwerks</strong></p>

    <p>I. Benutzer sind verpflichtet, andere Benutzer der Internetseite individuallist.de mit dem gleichen Respekt zu behandeln, wie sie diesen auch selbst erwarten dürfen. Insbesondere ist das Recht andere Benutzer auf deren eigene und oftmals auch abweichende Meinung zu achten.</p>

    <p>II. Bei Nutzung des sozialen Netzwerks ist es Benutzern insbesondere untersagt, die Internetseite individuallist.de</p>

    <p>a) als insoweit nicht vorgesehene Werbefläche für Webseiten, Waren oder Dienstleistungen jedweder Art,</p>

    <p>b) für das Sammeln von E-Mail-Adressen und sonstigen Daten der Benutzer zu Werbezwecken,</p>

    <p>c) für das Verbreiten von Rassismus, Hasspropaganda, Rechts- oder Linksextremen bzw. volksverhetzenden Äußerungen,</p>

    <p>d) für die Verbreitung von Pornografie und Obszönitäten,</p>

    <p>e) für eine Aufforderung zur Gewalt gegen Personen, Institutionen, Unternehmen, Tieren, Sachen oder Gewaltverherrlichung,</p>

    <p>f) für Beleidigungen, Verleumdungen, Drohungen oder Belästigungen oder für menschenverachtende Äußerungen,</p>

    <p>g) für Verletzungen von Rechten Dritter (Personen, Institutionen, Organisationen, Unternehmen),</p>

    <p>h) für gegen die guten Sitten verstoßende Inhalte,</p>

    <p>i) für Links auf Webseiten mit einem der oben genannten Inhalte</p>

    <p>zu benutzen.</p>

    <p><strong>§ 5 Verkaufsplattform</strong></p>

    <p>I. Die Individuallist UG (haftungsbeschränkt) betreibt auf der Internetseite individuallist.de eine Verkaufsplattform, auf welcher angemeldete Benutzer Waren und Dienstleistungen aller Art anbieten, vertreiben und erwerben können.</p>

    <p>II. Die Individuallist UG (haftungsbeschränkt) ist an den zwischen den Benutzern geschlossenen Verträgen nicht beteiligt. Insbesondere bietet die Individuallist UG (haftungsbeschränkt) selbst keine Waren und Dienstleistungen über die Verkaufsplattform an, gibt keine Gebote ab und nimmt Gebote und Annahmen nicht entgegen. Von daher bestehen keinerlei Ansprüche der Benutzer gegenüber der Individuallist UG aus den zwischen den Benutzern geschlossenen Verträgen.</p>

    <p>III. Die Individuallist UG (haftungsbeschränkt) ist nicht für die Speicherung von Daten und Informationen zur Beweissicherung für Benutzer verantwortlich.</p>

    <p>IV. Benutzer dürfen sämtliche Adressendaten, wie z.B. die E-Mail-Adressen, die sie durch die Nutzung der der Internetseite individuallist.de erhalten, nur für die vertragliche und vorvertragliche Kommunikation nutzen.</p>

    <p><strong>§ 6 Pflichten der Benutzer bei Nutzung der Verkaufsplattform</strong></p>

    <p>I. Es ist Benutzern nicht gestattet, Waren oder Dienstleistungen, deren Angebot, Verkauf oder Erwerb gegen gesetzliche Vorschriften, Rechte Dritter oder gegen die guten Sitten verstößt, auf der Internetseite individuallist.de anzubieten.</p>

    <p>II. Es liegt in der eigenen Verantwortung eines jeden Benutzers, dass seine Angebote oder Inhalte rechtmäßig sind und keine Rechte Dritter verletzen. Bei Kenntnis von unrechtmäßigen oder rechtsverletzenden Angeboten wird die Individuallist UG (haftungsbeschränkt) diese entfernen.</p>

    <p>III. Zur Einhaltung von etwaig bestehenden gesetzlichen Informationspflichten ist der Verkäufer selbst verpflichtet. Insbesondere ist ein Unternehmer (§ 14 BGB), welcher Waren oder Dienstleistungen an Verbraucher (§ 13 BGB) anbietet, dazu verpflichtet, alle gesetzlich vorgeschriebenen Verbraucherschutzinformationen zu erteilen und sie über das gesetzliche Widerrufs- oder Rückgaberecht zu belehren, sofern ein solches besteht.</p>

    <p>IV. Verkäufer sind gehalten, <em>ihre Angebote in die passende Kategorie</em> einstellen sowie ihr Angebot zutreffend mit Worten zu beschreiben. Hierbei sind alle für eine Kaufentscheidung wichtigen Eigenschaften und Merkmale sowie insbesondere Fehler und Abnutzungserscheinungen, welche sich wertmindernd auswirken, wahrheitsgemäß anzugeben. Sämtliche im Rahmen der Artikelbeschreibung verwandten Bilder und Texte müssen sich ausschließlich auf den angebotenen Artikel beziehen und dürfen nicht Rechte Dritter verletzen. Eine Rechtsverletzung Dritter ist in der Regel dann gegeben, wenn missbräuchlich Markennamen oder andere Suchbegriffe in die Bezeichnung oder Beschreibung der angebotenen Ware oder Dienstleistung eingefügt werden.</p>

    <p>V. Der Käufer ist in dem Angebot vollständig über sämtliche Zahlungsmöglichkeiten sowie über die Art der Lieferung und die Lieferzeiten zu informieren. Alle auf der Internetseite individuallist.de angegebenen Preise verstehen sich als Endpreise inklusive eventuell anfallender gesetzlichen Umsatzsteuer in der jeweils geltenden Höhe und anderer Steuern, Abgaben und sonstiger Preisbestandteile, jedoch zuzüglich etwaiger Liefer- und Versandkosten.</p>

    <p>VI. Der Verkäufer einer Ware muss sich in der Lage sein, dem Käufer unverzüglich nach Vertragsschluss Eigentum an der Ware zu verschaffen.</p>

    <p><strong>§ 7 Keine Erhebung von Gebühren und Provisionen</strong></p>

    <p>I. Die Benutzung der Internetseite individuallist.de ist für angemeldete Benutzer kostenfrei. Die Individuallist UG (haftungsbeschränkt) erhebt keine Gebühren für die Nutzung der Funktionen des sozialen Netzwerks. Auch wird keine Provision für Käufe und Verkäufe auf der Internetseite individuallist.de erhoben.</p>

    <p>II. Gegenwärtig finanziert sich die Individuallist UG (haftungsbeschränkt) ausschließlich durch die auf der Internetseite individuallist.de geschaltete Werbung.</p>

    <p><strong>§ 8 Kündigung, Sperrung der Nutzung</strong></p>

    <p>I. Der Nutzer kann sein Benutzerkonto und den Nutzungsvertrag jederzeit ohne Einhaltung einer Frist kündigen.</p>

    <p>II. Die Individuallist UG (haftungsbeschränkt) kann den Nutzungsvertrag jederzeit mit einer Frist von 14 Tagen zum Monatsende ordentlich kündigen.</p>

    <p>III. Für die Kündigungserklärung genügt eine schriftliche Mitteilung per Brief oder per E-Mail. Eine Erklärung der Individuallist UG (haftungsbeschränkt) geht mit Versendung einer E-Mail an eine vom Benutzer angegebene E-Mail-Adresse zu.</p>

    <p>IV. Verstößt ein Benutzer gegen vertragliche Pflichten insbesondere nach § 4 oder § 6 dieser Allgemeinen Geschäftsbedingungen, kann die Individuallist UG diesen Benutzer zeitweilig von einer Nutzung der Internetseite individuallist.de sperren. Ein Recht der Individuallist UG zur ordentlichen oder fristlosen Kündigung des Nutzungsvertrages bleibt von der Sperrung unberührt.</p>

    <p>III. Nach Kündigung und endgültiger Löschung eines Kontos besteht kein Anspruch auf Wiederherstellung der diesen Benutzer betreffenden Daten. Die erneute Anmeldung dieses Benutzers ist nicht zulässig. Sollte der gekündigte Benutzer ein Zweitkonto besitzen, so bezieht sich die Kündigung auch auf dieses Zweitkonto.</p>

    <p><strong>§ 9 Freistellung</strong></p>

    <p>I. Die Individuallist UG (haftungsbeschränkt) stellt Benutzern die Möglichkeit zur Verfügung, Texte, Bilder und Videos auf der Internetseite individuallist.de einzustellen. Die Benutzer verpflichten sich, nur Texte, Bilder und Videos einzustellen, die frei von Rechten Dritter sind. Insbesondere verpflichten sich alle Benutzer, bei der Einstellung von Texten, Bildern und Videos Urheberrechte Dritter zu wahren.</p>

    <p>II. Die Individuallist UG (haftungsbeschränkt) ist berechtigt, alle eingestellten Texte, Bilder und Videos teilweise oder vollständig zu löschen, wenn nach Auffassung der Individuallist UG (haftungsbeschränkt) die Möglichkeit besteht, dass durch die Einstellung dieser Texte, Bilder oder Videos Rechte Dritter verletzt werden könnten.</p>

    <p>III. Sollten Dritte eine Verletzung von Ihnen zustehenden Rechten durch auf der Internetseite individuallist.de eingestellte Texte, Bilder oder Videos rügen, so ist die Individuallist UG (haftungsbeschränkt) berechtigt, die entsprechenden Texte, Bilder oder Videodateien unabhängig von der Berechtigung der geltend gemachten Ansprüche Dritter von der Internetseite individuallist.de dauerhaft zu entfernen. Die Individuallist UG (haftungsbeschränkt) wird beanstandete Textdateien, Bilder oder Videos wiedereinstellen, wenn im Falle einer Beanstandung durch Dritte durch schriftliches Anerkenntnis oder rechtskräftige Entscheidung eines deutschen Gerichts festgestellt ist, dass der Benutzer berechtigt ist, die beanstandeten Texte, Bilder und Videodateien auf der Internetseite individuallist.de einzustellen.</p>

    <p>IV. Der Benutzer verpflichtet sich, die Individuallist UG (haftungsbeschränkt) von allen Ansprüchen, welche aus einer vom Benutzer zu vertretenen Verletzung von geltendem Recht beziehungsweise Rechten Dritter resultieren, sofort und vollumfänglich freizustellen. Zu den Rechten Dritter zählen insbesondere auch Schutzrechte, welche Dritten aus Urheberrechten oder gewerblichen Schutzrechten, insbesondere Markenrechten, zustehen. Die Verpflichtung des Benutzers zur Freistellung der die Individuallist UG (haftungsbeschränkt) umfasst insbesondere auch die Freistellung von nationalen und internationalen Rechtsverteidigungskosten. In jedem Fall ist der Benutzer gegenüber der Individuallist UG (haftungsbeschränkt) verpflichtet, vollständig und wahrheitsgemäß Auskunft zu erteilen und der Individuallist UG (haftungsbeschränkt) alle Informationen zur Verfügung zu stellen, die zur Prüfung der geltend gemachten Ansprüche nötig sind.</p>

    <p><strong>§ 10 Haftung der Individuallist UG (haftungsbeschränkt)</strong></p>

    <p>I. Die Haftung der Individuallist UG (haftungsbeschränkt), gleich aus welchem Rechtsgrund, beschränkt sich auf Vorsatz, grobe Fahrlässigkeit und einfache Fahrlässigkeit bei Verletzung einer für die Erreichung des Vertragszweckes wesentlichen Vertragspflicht durch die Individuallist UG (haftungsbeschränkt) oder ihre Erfüllungsgehilfen. Wesentliche Vertragspflichten sind hierbei Pflichten der Individuallist UG (haftungsbeschränkt), deren Verletzung den Vertragszweck gefährdet bzw. deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Benutzer als Vertragspartner regelmäßig vertrauen darf.</p>

    <p>II. Bei einfacher Fahrlässigkeit haftet die Individuallist UG (haftungsbeschränkt) nur für Schäden, die vorhersehbar und aufgrund der erbrachten Dienstleistungen typisch sind.</p>

    <p>III. Sämtliche in diesen Geschäftsbedingungen aufgeführten Haftungsbeschränkungen gelten nicht:</p>

    <p>a. bei Personenschäden,</p>
    <p>b. bei Schäden, die durch das Fehlen einer Beschaffenheit entstanden sind, die die Individuallist UG (haftungsbeschränkt) garantiert hat,</p>
    <p>c. bei Ansprüchen aus dem Produkthaftungsgesetz.</p>

    <p><strong>§ 11 Schriftform, anwendbares Recht und Gerichtsstand, salvatorische Klausel</strong></p>

    <p>I. Sämtliche Erklärungen, die im Rahmen des mit der Individuallist UG (haftungsbeschränkt) geschlossenen Vertrags über die Nutzung der Internetseite individuallist.de übermittelt werden, müssen in Schriftform oder per E-Mail erfolgen.</p>

    <p>II. Der Nutzungsvertrag einschließlich dieser Allgemeinen Geschäftsbedingungen unterliegt dem Recht der Bundesrepublik Deutschland.</p>

    <p>III. Ausschließlicher Gerichtsstand für alle im Zusammenhang mit dem Vertragsverhältnis einschließlich dieser Allgemeinen Geschäftsbedingungen sich ergebenden Streitigkeiten ist Düsseldorf, sofern der Benutzer Vollkaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen ist. Die Individuallist UG ist jedoch nach ihrer Wahl stets berechtigt, Klagen gegen den Benutzer an deren allgemeinem Gerichtsstand zu erheben.</p>

    <p>IV. Sollten diese Bestimmungen teilweise unwirksam oder lückenhaft sein, wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt.</p>

    <p>Düsseldorf, den 15. Januar 2018</p>
  </div>
</template>
